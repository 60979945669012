import React, { FC } from 'react';
// Styles
import styles from './PageBanner.module.scss';

interface PageBannerProps {
  title: string;
  imageUrl: string;
}

const PageBanner: FC<PageBannerProps> = ({ title, imageUrl }) => {
  return (
    <div className={styles.root}>
      <span className={styles.title}>{title}</span>

      <div className={styles.imageWrapper}>
        <img src={`${imageUrl}`} alt="page banner" className={styles.image} />
      </div>
    </div>
  );
};

export default PageBanner;
